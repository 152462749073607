import { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import { User } from "@types";
import { UpdatePassword } from "./components";
import { Form } from "@modules/common/components";
import { useLogin } from "@modules/auth/hooks";
import { useGetQueryData } from "@modules/common/hooks";
import { ReactComponent as Logo } from "@icons/logos/logo.svg";

export default function Login() {
  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });

  const user = useGetQueryData<User | undefined>(["user"]);

  const { mutate, isPending, error } = useLogin();

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  }

  // Smells bc api requires email AND username
  function handleSubmit() {
    mutate({ ...formState, username: formState.email });
  }

  if (user && user.force_password_change) return <UpdatePassword />;

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          {/* Card */}
          <div className="bg-white p-8 rounded-xl flex flex-col gap-5 shadow">
            <div className="flex justify-center">
              <Logo fill="black" />
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-secondary-10 mb-2">
                Log in
              </h2>
              {error ? (
                <h2 className=" mt-2 text-red-700">{error.message}</h2>
              ) : null}
            </div>

            <Form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-medium text-secondary-10"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={formState.email}
                    onChange={handleChange}
                    required
                    placeholder="Enter your email"
                    className="w-full bg-white text-secondary-10 px-4 py-3 rounded-lg outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:border-primary-50"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm/6 font-medium text-secondary-10"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={formState.password}
                    onChange={handleChange}
                    required
                    placeholder="Enter your password"
                    className="w-full bg-white text-secondary-10 px-4 py-3 rounded-lg outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:border-primary-50"
                  />
                </div>
              </div>
              <div className="py-b">
                <Link
                  to="/reset"
                  className="font-medium text-primary-50 hover:text-primary-40"
                >
                  Forgot your password?
                </Link>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={formState.password === ""}
                  className="w-full text-white bg-primary-50 py-3 px-4 rounded-lg hover:bg-primary-40 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isPending ? "Logging in..." : "Log in"}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
