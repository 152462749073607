import { useRequestPasswordReset } from "@modules/auth/hooks";
import { useState } from "react";
import { Link } from "react-router-dom";
import { EmailSentConfirmation } from "./components";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  const { mutate: forgotPassword, isSuccess } = useRequestPasswordReset();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    forgotPassword(email);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  if (isSuccess) return <EmailSentConfirmation email={email} />;

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          {/* Card */}
          <div className="bg-white p-8 rounded-xl flex flex-col gap-5 shadow">
            <div className="text-neutral-5 flex flex-col cols-1 gap-6">
              <div className="font-medium text-primary-50 hover:text-primary-40">
                <Link to="/" className="text-primary-40 cursor-pointer">
                  Go back
                </Link>
              </div>
              <div>
                <h1 className="text-2xl font-semibold text-secondary-10">
                  Forgot your password
                </h1>
                <p className="text-base text-secondary-10">
                  Enter your email below and we'll send you a link to reset your
                  password.
                </p>
              </div>
            </div>
            <div>
              <div className="mt-4">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm/6 font-medium text-secondary-10"
                    >
                      Email
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={handleChange}
                        required
                        placeholder="Enter your email"
                        className="w-full bg-white text-secondary-10 px-4 py-3 rounded-lg outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:border-primary-50"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="w-full text-white bg-primary-50 py-3 px-4 rounded-lg hover:bg-primary-40 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
