import { useForm } from '@modules/common/hooks';
import { FormEvent, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { CreoviaLoad } from '@modules/common/components';
import { useUpdatePassword } from '@modules/auth/hooks';
import { ReactComponent as Logo } from "@icons/logos/logo.svg";

interface FormState {
	password: string;
	password_confirm: string;
}

export default function UpdatePassword() {
	const queryClient = useQueryClient();
	const { formState, handleChange } = useForm<FormState>({
		password: '',
		password_confirm: '',
	});

	const [errorMessage, setErrorMessage] = useState('');

	const { mutate: updatePassword, isPending, error } = useUpdatePassword();

	useEffect(() => {
		if (error) setErrorMessage('There a problem resetting your password.');
	}, [error]);

	/**
	 * Converts an array of strings to a comma separated string
	 * @param arr - Array of strings
	 */
	const toCommaSeparatedString = (arr: string[]) => {
		if (!arr) return [];

		let string = '';

		arr.map((char) => {
			string += char + ', ';
		});

		return string.slice(0, -2);
	};

	const submitForm = async (password: string, password_confirm: string) => {
		updatePassword({ password: password, password_confirm: password_confirm });
	};

	const isValidPassword = (password: string, password_confirm: string) => {
		// eslint-disable-next-line no-control-regex
		const regex = /[^\x00-\x7F]/g;
		const illegalChars = toCommaSeparatedString(password.match(regex) as string[]);

		if (/\s/g.test(password)) { // Regex checks for whitespace
			setErrorMessage('Password cannot contain spaces');
			return false;
		} else if (illegalChars.length > 0) {
			setErrorMessage(`Password cannot contain the following characters: ${illegalChars}`); // a-Z 0-9 !@#$%^ -_
			return false;
		} else if (password !== password_confirm) {
			setErrorMessage('Passwords do not match');
			return false;
		} else if (password.length <= 8) {
			setErrorMessage('Password must be longer than 8 characters.');
			return false;
		}

		return true;
	};

	const signOutUser = async () => {
		localStorage.removeItem('refresh');
		queryClient.setQueryData(['user'], null);
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const { password, password_confirm } = formState;

		try {
			setErrorMessage('');

			if (isValidPassword(password, password_confirm)) {
				await submitForm(password, password_confirm);
			}

		} catch (error) {
			setErrorMessage('There was an error resetting your password.');
		}
	};

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white px-6 pt-16 pb-4 shadow sm:rounded-lg">
						<div className="flex items-center w-full justify-center">
							<Logo />
						</div>
						<div>
							<h2 className="text-2xl font-bold tracking-tight text-black">
								Reset Password
							</h2>
							<div className="text-sm tracking-tight text-black">
								Please use the form below to update your password
							</div>
						</div>
						<div className="">
							<div className="mt-6">
								<form onSubmit={handleSubmit} className="space-y-6">
									<div>
										<label
											htmlFor="email"
											className="block text-sm/6 font-medium text-secondary-10"
										>
											Password
										</label>
										<div className="mt-2">
											<input
												id="password"
												name="password"
												type="password"
												value={formState.password}
												onChange={handleChange}
												required
												placeholder="Enter your new password"
												className="w-full bg-white text-secondary-10 px-4 py-3 rounded-lg outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:border-primary-50"
											/>
										</div>
										<div className="text-neutral-50 mt-2 px-3">
											Passwords should be a minimum of 8 characters, with no spaces.
										</div>
									</div>
									<div>
										<label
											htmlFor="password_confirm"
											className="block text-sm/6 font-medium text-secondary-10"
										>
											Confirm Password
										</label>
										<div className="mt-2">
											<input
												id="password_confirm"
												name="password_confirm"
												type="password"
												value={formState.password_confirm}
												onChange={handleChange}
												required
												placeholder="Confirm your new password"
												className="w-full bg-white text-secondary-10 px-4 py-3 rounded-lg outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:border-primary-50"
											/>
										</div>
									</div>
									{errorMessage ? (
										<div className="text-red-700 mt-2">{errorMessage}</div>
									) : (
										<div className="text-red-700 mt-2"></div>
									)}
									<div>
										<button
											type="submit"
											disabled={formState.password === ""}
											className="w-full text-white bg-primary-50 py-3 px-4 rounded-lg hover:bg-primary-40 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
										>
											{isPending ? "Loading..." : "Submit"}
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className="w-full justify-end flex mt-3">
							<button className="text-primary-40 hover:text-primary-50" onClick={signOutUser}>
								<span className="text-base font-medium text-primary-40 hover:text-primary-50"> &larr;</span>
								Go back
							</button>
						</div>
					</div>
				</div>

				<div className={`h-full ${isPending ? '' : 'hidden'}`}>
					<CreoviaLoad />
				</div>
			</div>
		</>
	);
}